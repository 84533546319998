<div
  class="row border-bottom"
  *ngIf="globalData.loggedIn && globalData.appReady"
>
  <nav class="navbar navbar-static-top margin-0" role="navigation">
    <div class="col-md-12 user-context-bar">
      <!-- <div class="d-flex w-100"> -->
      <ul
        class="nav navbar-top-links navbar-left system-context-bar mr-auto"
        *ngIf="showEnvs"
      >
        <li role="presentation" class="dropdown">
          <div
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            class="dropdown-toggle"
          >
            <span>Multi-Tenant <fa-icon [icon]="faCaretDown"></fa-icon></span>
          </div>
          <ul class="dropdown-menu dropdown-menu-left">
            <li>
              <a href=""
                ><span>{{ "BLUECHOICE" }}</span>
              </a>
            </li>
            <li>
              <a href=""
                ><span>{{ "BCBSN" }}</span>
              </a>
            </li>
            <li>
              <a href=""
                ><span>{{ "BCBST" }}</span>
              </a>
            </li>
            <li>
              <a href=""
                ><span>{{ "MEDICAID" }}</span>
              </a>
            </li>
            <li class="active">
              <a href=""
                ><span>{{ "Multi-Tenant" }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <ul class="nav navbar-top-links navbar-right user-context-bar">
        <li>
          <a *ngIf="globalData.username">Hi, {{ globalData.username }}</a>
        </li>
        <li *ngIf="!securityService.isSSO">
          <a (click)="logout()">
            <fa-icon [icon]="faSignOutAlt"></fa-icon> Log out
          </a>
        </li>
      </ul>
    </div>
    <div class="col-md-12 cheetah-nav">
      <a class="navbar-brand mr-auto" href="#">
        <img
          src="../assets/Images/Icario_Ocean_RGB.png"
          width="200px"
          alt=""
          loading="lazy"
          class="mt-3"
          *ngIf="globalData.appConfig.useLogo === 'true'"
        />
      </a>
      <ul class="nav navbar-top-links navbar-right">
        <li
          role="presentation"
          class="dropdown"
          [ngClass]=""
          *ngIf="globalData.isAccountManager"
          (mouseenter)="showSubmenu[4] = true"
        >
          <a [routerLink]="'/dashboard/'">
            <span>{{ "Dashboard" }}</span>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-left"
            *ngIf="showSubmenu[4]"
            (click)="showSubmenu[4] = false"
          >
            <li [ngClass]="" *ngIf="globalData.isAccountManager">
              <a [routerLink]="'/redemption-dashboard/'">
                <span>{{ "Redemptions Dashboard " }}</span>
              </a>
            </li>
          </ul>
        </li>

        <li [ngClass]="" *ngIf="globalData.isProgramManager">
          <a [routerLink]="'/programs/'">
            <span>{{ "Programs" }}</span>
          </a>
        </li>

        <li [ngClass]="" *ngIf="globalData.isProgramManager">
          <a [routerLink]="'/sponsors/'"
            ><span>{{ "Sponsors" }}</span>
          </a>
        </li>

        <li [ngClass]="" *ngIf="globalData.isProgramManager">
          <a [routerLink]="'/communications/'"
            ><span>{{ "Communications" }}</span>
          </a>
        </li>

        <li
          role="presentation"
          class="dropdown"
          [ngClass]=""
          (mouseenter)="showSubmenu[0] = true"
        >
          <a
            [routerLink]="'/members/'"
            *ngIf="
              globalData.isPartner ||
              securityService.allowFullMemberFunctionality
            "
            ><span>{{ "Members" }}</span></a
          >
          <ul
            class="dropdown-menu dropdown-menu-left"
            *ngIf="showSubmenu[0]"
            (click)="showSubmenu[0] = false"
          >
            <li ui-sref-active="active" *ngIf="globalData.isCSRManager">
              <a [routerLink]="'/interactions/'"
                ><span>{{ "Notes Management" }}</span>
              </a>
            </li>
          </ul>
        </li>

        <li
          role="presentation"
          *ngIf="globalData.isAccountManager"
          class="dropdown"
          [ngClass]=""
          (mouseenter)="showSubmenu[1] = true"
        >
          <a [routerLink]="'/integrations/'" *ngIf="globalData.isAccountManager"
            ><span>{{ "Integrations" }}</span></a
          >
          <ul
            class="dropdown-menu dropdown-menu-left"
            *ngIf="showSubmenu[1]"
            (click)="showSubmenu[1] = false"
          >
            <li ui-sref-active="active" *ngIf="globalData.isProgramManager">
              <a [routerLink]="'/integrations/'"
                ><span>{{ "Inbound Batch" }}</span>
              </a>
            </li>
            <li *ngIf="globalData.isProgramManager">
              <a [routerLink]="'/outbounds/'">{{ "Outbound Batch" }}</a>
            </li>
            <li *ngIf="globalData.isProgramManager">
              <a [routerLink]="'/eventSources'" ui-sref="eventSources">{{
                "Event Sources"
              }}</a>
            </li>
            <li *ngIf="globalData.isProgramManager">
              <a [routerLink]="'/events'" ui-sref="events">{{ "Events" }}</a>
            </li>
            <li *ngIf="globalData.isProgramManager">
              <a [routerLink]="'/measures'" ui-sref="measures">{{
                "Measures"
              }}</a>
            </li>
          </ul>
        </li>
        <li
          role="presentation"
          [ngClass]=""
          *ngIf="globalData.isAccountManager || securityService.allowReportsTab"
        >
          <a [routerLink]="'/reports/'"
            ><span>{{ "Reports" }}</span>
          </a>
        </li>

        <li
          role="presentation"
          *ngIf="globalData.isCSRManager && securityService.allowProcessingTab"
          class="dropdown"
          ui-sref-active="active"
          (mouseenter)="showSubmenu[2] = true"
          [ngClass]=""
        >
          <a
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            [routerLink]="'/processing-log/'"
          >
            <span>{{ "Processing" }}</span>
          </a>
          <ul
            class="dropdown-menu dropdown-menu-left"
            *ngIf="showSubmenu[2]"
            (click)="showSubmenu[2] = false"
          >
            <li ui-sref-active="active" *ngIf="globalData.isCSRManager">
              <a [routerLink]="'/processing-log/'">
                <span>{{ "Processing Log" }}</span>
              </a>
            </li>
            <li *ngIf="globalData.isAccountManager">
              <a [routerLink]="'/activity-search/'">{{ "Activity Search" }}</a>
            </li>
          </ul>
        </li>

        <li
          role="presentation"
          class="dropdown"
          [ngClass]=""
          *ngIf="globalData.isProgramManager"
          (mouseenter)="showSubmenu[3] = true"
        >
          <a [routerLink]="'/system/'"
            ><span>{{ "System" }}</span></a
          >
          <ul
            *ngIf="showSubmenu[3]"
            class="dropdown-menu dropdown-menu-right"
            (click)="showSubmenu[3] = false"
          >
            <li ui-sref-active="active">
              <a [routerLink]="'/segments/'"
                ><span>{{ "Segments" }}</span></a
              >
            </li>
            <li ui-sref-active="active" *ngIf="globalData.isAdmin">
              <a [routerLink]="'/users/'">{{ "Users" }}</a>
            </li>
            <li ui-sref-active="active" *ngIf="globalData.isProgramManager">
              <a [routerLink]="'/partners/'">{{ "Partners" }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div *ngIf="globalData.loggedIn && globalData.appReady">
  <app-bread-crumbs></app-bread-crumbs>

  <!-- Open Tickets -->
  <app-open-tickets
    #openTicket
    class="open-tickets"
    [(isTicketsOpened)]="isTicketsOpened"
    [(interactionText)]="interactionText"
    [(escalateTooltip)]="escalateTooltip"
    [(ticket)]="ticket"
    [(openedTickets)]="openedTickets"
    *ngIf="ticket"
  ></app-open-tickets>

  <!-- Tickets -->
  <app-tickets
    class="tickets-list-popup"
    [(isTicketsOpened)]="isTicketsOpened"
    [(interactionText)]="interactionText"
    [(ticket)]="ticket"
    [(escalateTooltip)]="escalateTooltip"
    [(openedTickets)]="openedTickets"
  ></app-tickets>

  <div class="footer footer-center">
    Copyright &copy; {{ year }} ChipRewards release-3.5.1-478be079f2bb3-
  </div>
</div>
<router-outlet></router-outlet>
